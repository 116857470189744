import React from 'react';
import { Link } from 'react-router-dom';
import logo from './image/logo.jpeg';
import logo1 from './image/Mail 1.png';
import logo2 from './image/Locat 1.png';
import logo3 from './image/Call 1.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <footer className="relative z-1 bg-[#000B14] text-white py-8 md:w-full">
            <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 border-b-2 border-gray-500 pb-12 mt-4 sm:grid-cols-2 md:grid-cols-4 gap-8">
                    <div className='text-lg form-text:text-3xl xl:ml-8'>
                        <h3 className="text-form-text font-semibold  form-text:text-4xl mb-4 form-text:mb-14">{t('footer.f1')}</h3>
                        <ul className="space-y-2 form-text:space-y-4">
                          
                            <li><Link to="#" className="hover:underline">{t('footer.f2')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f3')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f4')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f5')}</Link></li>
                        </ul>
                    </div>
                    <div className='text-lg form-text:text-3xl xl:ml-8'>
                        <h3 className="text-form-text font-semibold form-text:text-4xl mb-4 form-text:mb-14">{t('footer.f6')}</h3>
                        <ul className="space-y-2  form-text:space-y-4">
                            <li><Link to="#" className="hover:underline">{t('footer.f7')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f8')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f9')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f10')}</Link></li>
                        </ul>
                    </div>
                    <div className='text-lg form-text:text-3xl'>
                        <h3 className="text-form-text font-semibold form-text:text-4xl mb-4 form-text:mb-14">{t('footer.f11')}</h3>
                        <ul className="space-y-2  form-text:space-y-4">
                            <li><Link to="#" className="hover:underline">{t('footer.f12')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f13')}</Link></li>
                            <li><Link to="#" className="hover:underline">{t('footer.f14')}</Link></li>
                        </ul>
                    </div>
                    <div className='text-lg form-text:text-3xl'>
                        <h3 className="text-form-text font-semibold form-text:text-4xl mb-4 form-text:mb-14">{t('footer.f15')}</h3>
                        <ul className="space-y-2 form-text:space-y-4">
                            <li className='flex'><img src={logo1} alt='mes' className='w-5 h-5 mt-2'/><p  className="ml-4 sm:break-all">{t('footer.f16')}</p></li>
                            <li className='flex'><img src={logo2} alt='loc' className='w-5 h-5 mt-3'/><p className="ml-4"><span className='text-base form-text:text-3xl '>{t('footer.f17')}</span>, {t('footer.f18')}, <span className=' text-base form-text:text-3xl'>{t('footer.f19')}</span> {t('footer.f20')}, {t('footer.f23')}, {t('footer.f21')}</p></li>
                            <li className='flex'><img src={logo3} alt='phone' className='w-5 h-5 mt-1'/><p className="ml-4">
                            +1 (586) 801-2246
                            </p></li>
                            <li className='ml-4'><p className="ml-4">
                            +33 6 51 57 47 58
                            </p></li>
                            <li className='ml-4'><p className="ml-4">
                            +47 968 29 285
                            </p></li>
                            <li className='ml-4'><p className="ml-4">
                            +31 6 17451034
                            </p></li>
                            
                            


                            
                        
                           
                           
                            
                            
                        </ul>
                    </div>
                </div>
                {/* <div className="flex mt-8 text-center text-lg font-normal form-text:text-3xl justify-center">
                    <img src={logo} alt="Logo" className="mb-2 form-text:w-24 " />
                    <p className='mt-6 ml-4'>© 2024 World Tamil Siragam. All Rights Reserved</p>
                </div> */}
                <div className="flex flex-col items-center mt-8 text-center  sm:text-xl text-lg font-normal sm:flex-row  sm:justify-center">
                <img src={logo} alt="Logo" className="mb-4  h-16 w-16 rounded-full sm:mb-0 sm:mr-4" />
                <p className="mt-0  font-normal sm:ml-4">© 2024 World Tamil Siragam. All Rights Reserved</p>
              </div>
            </div>
        </footer>
    );
}

export default Footer;
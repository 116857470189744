import React from "react";
// import Multipleheader from "../header/multiple-header";
import Contactform from '../contact/contactform';
import Header from "../header/header";

const Contactus = () =>{
    return(
        <>
        {/* <Multipleheader/> */}
        <Header/>
        <Contactform/>
        </>
    )
}
export default Contactus;
import React from 'react';
import { useTranslation } from 'react-i18next';

const MyComponent = () => {
  const { t } = useTranslation();

  return (
    <div id='mission-vision' className='flex flex-col items-center p-4 xl:pt-32 sm:p-8 md:p-12 2xl:p-20 '>
      {/* <h1 className="text-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl xl:font-medium 2xl:text-5xl 2xl:leading-normal font-medium mb-6 sm:mb-6 md:mb-8 leading-snug xl:leading-snug md:leading-normal">
        {t('structure.subtitle')}
      </h1> */}

      <div className="flex flex-col md:flex-row items-center justify-center w-full">
        <div className="w-full md:w-3/5 p-2 2xl:p-6 xl:p-8 ">
          <div className="mb-6 md:mb-8 mt-4 xl:mb-8 md:mt-0">
            <h2 className="text-center text-xl sm:text-2xl md:text-3xl 2xl:text-4xl xl:tracking-wider font-semibold 2xl:mb-6 mb-4 md:mb-4">
              {t('structure.structure')}
            </h2>
            <p className="text-center text-black-300 text-sm sm:text-base xl:text-sm 2xl:text-lg xl:tracking-wider 2xl:leading-relaxed leading-relaxed xl:leading-relaxed lg:pb-6 md:pb-8">
              {t('structure.p')}
            </p>
          </div>

          <div>
            <h2 className="text-center text-xl sm:text-2xl md:text-3xl 2xl:text-4xl xl:tracking-wider font-semibold 2xl:mb-6 mb-2 md:mb-4">
              {t('structure.visions')}
            </h2>
            {Array.from({ length: 10 }, (_, i) => (
              <div key={i} className="bg-white p-6 rounded-lg shadow-md w-full mb-4 transform transition-transform duration-300 hover:scale-105">
                <p className="text-start text-black-300 text-sm sm:text-base leading-relaxed xl:text-lg xl:tracking-wider 2xl:text-lg 2xl:leading-relaxed xl:leading-relaxed mt-4">
                  &#9827; {t(`structure.p${i + 1}`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyComponent;

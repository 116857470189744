import React from 'react';
import { useLocation } from 'react-router-dom';
// import Multipleheader from '../header/multiple-header';
import { useTranslation } from 'react-i18next';
import Header from '../header/header';

const Readmorenews = () => {
    const location = useLocation();
    const newsItem = location.state?.newsItem; // Get the passed news item
    const { t, i18n } = useTranslation();

    return (
        <>
            {/* <Multipleheader /> */}
            <Header/>
            {newsItem ? (
                <div className="flex flex-col items-center justify-center min-h-screen">
                    
                    <div className="p-8 rounded-xl text-center mt-16">
                        <img 
                            src={newsItem.image.startsWith('http') ? newsItem.image : `${process.env.REACT_APP_API_URL}/public/${newsItem.image}`} 
                            alt={newsItem.title} 
                            className="mx-auto mb-6 border-4 shadow-md transition-transform transform hover:scale-105"
                        />
                        <h1 className="sm:text-3xl text-xl sm:text-3xl font-bold text-gray-800 mb-4">
                        {i18n.language === 'TA' ? newsItem.title_ta:newsItem.title_en}
                        </h1>
                        <p className="text-lg text-gray-600 leading-relaxed text-justify max-w-screen-md mx-auto">
                        
                            {i18n.language === 'TA' ? newsItem.description_ta:newsItem.description_en}
                            
                        </p>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center min-h-screen">
                    <p className="text-lg text-gray-600 leading-relaxed text-justify">
                        No news item selected.
                    </p>
                </div>
            )}
        </>
    );
};

export default Readmorenews;




import React from "react";
// import EventFestival from "./eventfestival";
import EventBanner from "./eventbanner";
import EventSection from "./eventsection";

import Donate from "../../Donate/donate";
import Contact from "../../contact/contactform";


const EventHome = () => {
    return(
        <>
        <EventBanner/>
        {/* <EventFestival/> */}
        
        <EventSection/>
        
       <Donate/>
       <Contact/>
</>
    );
}
export default EventHome;
const Allapi = {
    NEWS_getapi: `/api/news/news`,
    VISION_getapi: `/api/vision/vision`,
    FESTIVAL_getapi: `/api/festival/festival`,
    CONTACT_postapi: `api/contact/contacts`,
    STRUCTURE_getapi: `api/structure/structure`,
    INVITATION_getapi: `api/invitation/invitation`,
    PUBLISH_getapi: `api/publish/publish`,
  };

 const language ={
  EN: 'en',
  TA: 'ta',
};
module.exports = {Allapi, language};
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import bannerImage from '../home/images/banner.png';
import Header from '../header/header.js';
import { useTranslation } from 'react-i18next';
import image1 from '../home/images/thiruvalluvar.png';
import image2 from '../home/images/my-image (1).png'; 
import image3 from '../home/images/center-image.png'; 

// aos
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  const { t } = useTranslation();

  // Define images for the slideshow
  const images = [image1, image2, image3];
  
  // State to track the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const headings = [
    { text: t('banner.title1'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px]  big-screen:leading-loose head-home:text-[400%] text-3xl lg:tracking-wider big-screen:text-[110px] xl:text-[330%]  lg:leading-snug sm:text-6xl lg:text-[350%]  font-bold' },
    { text: t('banner.title2'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] text-3xl sm:text-6xl head-home:text-[400%] lg:tracking-wider  big-screen:text-[110px] lg:text-[350%] xl:text-[330%] border-b-2 big-screen:border-b-4 font-bold border-[#FFFFFF] big-screen:pb-24 pb-4 md:pb-14 iphonexr:pb-8' }
  ];

  const buttonLinks = [
    { text: t('banner.membershipRegistration'), className: 'rounded-full  bg-[#281E5D] big-screen:text-2xl big-screen:px-8 big-screen:py-6 px-6 py-4', to: '/membership' },
    { text: t('banner.registernumber'), className: 'flex items-center font-base 2xl:text-lg  big-screen:text-3xl text-white-900', to: '/' }
  ];

  useEffect(() => {
    AOS.init();
    
    // Set interval to change the image every 3 seconds (3000ms)
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the time interval as needed

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [images.length]);

  // Trigger AOS refresh every time the image changes
  useEffect(() => {
    AOS.refresh();
  }, [currentImageIndex]);

  

  return (
    <>
    <div id='home' className="relative w-[100%] min-h-screen bg-fixed bg-cover item-center place-items-center text-white" style={{ backgroundImage: `url(${bannerImage})` }}>
      <Header />
      <div className="fixed z-0 small-screen:pt-96 medium-screen:mx-32 flex flex-col xl:pt-[20%] pt-40  big-screen:pt-[28rem] big-screen:mx-32
       head-home:gap-32 xl:gap-[10%] head-home:mx-20 very-screen:pt-[21%] medium-screen:pt-[19%] head-home:pt-[21%] big-screen:gap-44 mx-4 lg:mx-14 md:pt-44 md:flex-row gap-20">
        <div>
        <div data-aos="fade-right" data-aos-delay="30" data-aos-duration="2000">

          <div className="flex flex-col text-start text-[#F9FAFB] iphonexr:gap-3 iphonexr-pb-8">
            {headings.map((heading, index) => (
              <span key={index} className={heading.className}>
                {heading.text}
              </span>
            ))}
          </div>
          </div>
            
          
          <div className='mt-8 md:mt-14 big-screen:mb-12 big-screen:mt-28 flex flex-col big-screen:text-xl md:flex-row gap-4 md:gap-10 items-center space-x-0 md:space-x-4 iphonexr:gap-10'>
            {buttonLinks.map((link, index) => (
              <Link key={index} to={link.to} className={`${link.className} transition-transform duration-300 transform hover:scale-110`}>
                {link.text}
                {/* {link.to === '/' && (
                  <i className='ml-2 font-light'>
                    
                  </i>
                )} */}
              </Link>
            ))}
          </div>
        </div>
        <div className="relative" style={{ marginTop: '-100px' }}>
          <div 
            data-aos="zoom-in" 
            data-aos-delay="30" 
            data-aos-duration="2000"
            key={currentImageIndex} // Important for AOS to recognize image changes
          >
            <img src={images[currentImageIndex]} alt='slideshow' className='h-[450px]' />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Home;

//demo1
// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import bannerImage from '../home/images/banner.png';
// import Header from '../header/header.js';
// import { useTranslation } from 'react-i18next';
// import image1 from '../home/images/thiruvalluvar.png';
// import image2 from '../home/images/my-image (1).png'; 
// import image3 from '../home/images/center-image.png'; 

// // aos
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Home = () => {
//   const { t } = useTranslation();

//   // Define images for the slideshow
//   const images = [image1, image2, image3];
  
//   // State to track the current image index
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   // State to handle WTS letter-by-letter display and reverse animation
//   const [wtsIndex, setWtsIndex] = useState(0);
//   const [isReversing, setIsReversing] = useState(false); // Track if it's reversing
//   const wtsText = "WTS";

//   const headings = [
//     { text: 'World Tamil Siragam', className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] big-screen:leading-loose head-home:text-[400%] text-3xl lg:tracking-wider big-screen:text-[110px] xl:text-[330%] lg:leading-snug sm:text-6xl lg:text-[350%] font-bold text-center' },
//     { text: 'உலகத் தமிழ்ச் சிறகம்', className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] text-3xl sm:text-6xl head-home:text-[400%] lg:tracking-wider big-screen:text-[110px] lg:text-[350%] xl:text-[330%] border-b-2 big-screen:border-b-4 font-bold border-[#FFFFFF] big-screen:pb-24 pb-4 md:pb-14 iphonexr:pb-8 text-center' }
//   ];

//   const buttonLinks = [
//     { text: t('banner.membershipRegistration'), className: 'rounded-full bg-[#281E5D] big-screen:text-2xl big-screen:px-8 big-screen:py-6 px-6 py-4', to: '/membership' },
//     { text: t('banner.registernumber'), className: 'flex items-center font-base big-screen:text-3xl text-white-900', to: '/contactus' }
//   ];

//   useEffect(() => {
//     AOS.init();
    
//     // Set interval to change the image every 3 seconds (3000ms)
//     const interval = setInterval(() => {
//       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000); // Change the time interval as needed

//     // Cleanup interval on component unmount
//     return () => clearInterval(interval);
//   }, [images.length]);

//   // Trigger AOS refresh every time the image changes
//   useEffect(() => {
//     AOS.refresh();
//   }, [currentImageIndex]);

//   // Display WTS letters one by one, then reverse (hide) letter by letter without gap
//   useEffect(() => {
//     const wtsInterval = setInterval(() => {
//       if (!isReversing) {
//         // Forward animation (display letters)
//         setWtsIndex((prevIndex) => {
//           if (prevIndex + 1 === wtsText.length + 1) {
//             setIsReversing(true); // Start reversing once the word is fully displayed
//             return prevIndex;
//           }
//           return prevIndex + 1;
//         });
//       } else {
//         // Reverse animation (hide letters)
//         setWtsIndex((prevIndex) => {
//           if (prevIndex === 0) {
//             setIsReversing(false); // Start displaying again after it's fully hidden
//             return prevIndex;
//           }
//           return prevIndex - 1;
//         });
//       }
//     }, 300); // Adjust the delay to 300ms for faster transitions

//     return () => clearInterval(wtsInterval);
//   }, [isReversing]);

//   return (
//     <div id='home' className="relative w-[100%] min-h-screen bg-fixed bg-cover item-center place-items-center text-white" style={{ backgroundImage: `url(${bannerImage})` }}>
//       <Header />
//       <div className="fixed z-0 small-screen:pt-96 medium-screen:mx-32 flex flex-col xl:pt-[20%] pt-40  big-screen:pt-[28rem] big-screen:mx-32
//        head-home:gap-32 xl:gap-[10%] head-home:mx-20 very-screen:pt-[21%] medium-screen:pt-[19%] head-home:pt-[21%] big-screen:gap-44 mx-4 lg:mx-14 md:pt-44 md:flex-row gap-20">
        
//         {/* Content Section */}
//         <div>
//           {/* Heading Section (always visible) */}
//           <div className="flex flex-col text-center text-[#F9FAFB] iphonexr:gap-3 iphonexr-pb-8 items-center">
//             {/* First heading (World Tamil Siragam) */}
//             <span className={headings[0].className}>
//               {headings[0].text}
//             </span>

//             {/* WTS letters displayed one by one */}
//             <span className="md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] text-3xl sm:text-6xl head-home:text-[400%] lg:tracking-wider big-screen:text-[110px] lg:text-[350%] xl:text-[330%] font-bold text-center my-4">
//               {wtsText.slice(0, wtsIndex)}
//             </span>

//             {/* Second heading (உலகத் தமிழ்ச் சிறகம்) */}
//             <span className={headings[1].className}>
//               {headings[1].text}
//             </span>
//           </div>

//           {/* Buttons (always visible) */}
//           <div className='mt-8 md:mt-14 big-screen:mb-12 big-screen:mt-28 flex flex-col big-screen:text-xl md:flex-row gap-4 md:gap-10 items-center space-x-0 md:space-x-4 iphonexr:gap-10'>
//             {buttonLinks.map((link, index) => (
//               <Link key={index} to={link.to} className={link.className}>
//                 {link.text}
//               </Link>
//             ))}
//           </div>
//         </div>

//         {/* Slideshow Section */}
//         <div className="relative" style={{ marginTop: '-100px' }}>
//           <div 
//             data-aos="zoom-in" 
//             data-aos-delay="30" 
//             data-aos-duration="2000"
//             key={currentImageIndex} // Important for AOS to recognize image changes
//           >
//             <img src={images[currentImageIndex]} alt='slideshow' className='h-[450px]' />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;













import { Link } from 'react-router-dom';
import Logo from '../home/images/WTS-Full-Logo-WT.png';

const CmsHeader = () => {

  return (
    <>
      <div className={"navbar text-white mb-8 z-30 fixed w-full transition-all duration-300 bg-blue-950"}>
        <div className="flex justify-between items-center mb-2 mx-4 head-home:my-2 medium-screen:mx-32 head-home:mx-20 lg:mx-12 big-screen:mx-24">
          {/* Logo Column */}
          <div className="flex items-center">
            <Link to="/" ><img src={Logo} alt="logo" className="mt-2 mx-0 mr-2 lg:mr-2 big-screen:w-[450px] 
            big-screen:h-[100px] sm:w0[290px] sm:[59px] w-[240px] h-[55px]" /></Link>
          </div>

          {/* Admin Text Column */}
          <div className="flex items-center justify-end w-1/2">
            <Link to="/adminlogin" className='lg:ml-5 mt-3'>Admin</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CmsHeader;

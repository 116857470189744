import React, { useState, useEffect } from 'react';
import { get } from '../../service/apiservice';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EventSection() {
  const [eventsData, setEventsData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [popupVideo, setPopupVideo] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [expandedDescriptionId, setExpandedDescriptionId] = useState(null); // Track which description is expanded

  useEffect(() => {
    const getEventsData = async (lang) => {
      try {
        const response = await get(`/api/festival/festival?lang=${lang}`);
        const data = response.data.data;

        if (data.length > 0) {
          setSelectedEvent(data[0]); // Assuming the first event should be selected
        }

        setEventsData(data);
        console.log("data", data);
      } catch (error) {
        console.error('Failed to fetch events data:', error);
      }
    };

    getEventsData(i18n.language);
  }, [i18n.language]);

  const lightbox_open = (src) => {
    if (src) {
      setPopupVideo(src.startsWith('blob') ? src : `${process.env.REACT_APP_API_URL}/${src}`);
      setLightboxOpen(true);
    }
  };

  const lightbox_close = () => {
    setPopupVideo('');
    setLightboxOpen(false);
  };

  const handleGallery = (event) => {
    navigate('/galleryhome', { state: { eventsData: event } });
  };

  const toggleReadMore = (id) => {
    setExpandedDescriptionId(expandedDescriptionId === id ? null : id);
  };
  return (
    <>
      {eventsData.filter(event => event.isDisabled).map((event, index) => (
        <div key={index} className="px-4 py-8 md:px-20 md:py-8 mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column */}
            <div className="md:mr-8">
              <h1 className="text-2xl md:text-3xl font-semibold text-black-800  event-text:text-4xl">
                {i18n.language === 'TA' ? event.title_ta : event.title_en}
              </h1>
              <p className="text-sm md:text-base lg:text-xl text-black-800 mt-2  event-text:text-3xl">
                {/* {i18n.language === 'TA' ? event.description_ta : event.description_en} */}
                <p className="text-sm md:text-base lg:text-xl text-black-800 mt-2 event-text:text-3xl text-justify">
                {expandedDescriptionId === index
                  ? i18n.language === 'TA'
                    ? event.description_ta
                    : event.description_en
                  : i18n.language === 'TA'
                  ? `${event.description_ta?.substring(0, 100) ?? ''}...`
                  : `${event.description_en?.substring(0, 100) ?? ''}...`}
                <span
                  className="text-blue-500 cursor-pointer ml-2"
                  onClick={() => toggleReadMore(index)}
                >
                  {expandedDescriptionId === index ? t('Read Less') : t('Read More')}
                </span>
              </p>
                
                
              </p>
            </div>

            {/* Right Column */}
            <div className="md:text-right mt-5">
              <hr className="md:my-0 border-gray-500 md:border-b-0" />
              <div className="space-y-4 mt-10">
                <div key={index} className="text-base md:text-lg text-gray-400">
                  <p>{i18n.language === 'TA' ? event.date_ta : event.date_en}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 md:px-8 lg:px-0 py-4 md:py-8 lg:py-16">
            <div className="grid grid-cols-1 lg:grid-cols-10 gap-4">
              {/* Left Section */}
              <div className="col-span-1 lg:col-span-7 mt-8 lg:mt-0">
                <div className="relative bg-black rounded-2xl w-full overflow-hidden event-text:h-[900px] event-text:w-[1500px]">
                  <img
                    src={event.image?.filePath?.startsWith('blob') ? event.image.filePath : `${process.env.REACT_APP_API_URL}/${event.image?.filePath}`}
                    alt="Event"
                    className="w-full h-auto md:h-[500px] rounded-2xl object-cover cursor-pointer event-text:h-[900px] "
                    // style={{ filter: 'brightness(50%)' }}
                  />
                </div>
              </div>
            {/* <div className="col-span-1 lg:col-span-7 mt-8 lg:mt-0">
  <div className="relative bg-black rounded-2xl w-full h-[300px] md:h-[400px] lg:h-[600px] overflow-hidden">
    <img
      src={event.image?.filePath?.startsWith('blob') ? event.image.filePath : `${process.env.REACT_APP_API_URL}/${event.image?.filePath}`}
      alt="Event"
      className="w-full h-full rounded-2xl object-cover cursor-pointer"
    />
  </div>
</div> */}



              {/* Right Section */}
              <div className="col-span-1 lg:col-span-3 w-full flex flex-col items-center">
                <div className="border border-gray-300 rounded-2xl py-8 px-4 w-full  event-text:h-[300px]">
                  <h1 className="text-base lg:text-2xl font-bold text-center mb-2 lg:mb-4">
                    {t('event.group')}
                  </h1>
                  {/* <h1 className="ml-2 lg:ml-8 text-base lg:text-2xl font-bold mb-4">
                    {t('event.events')}
                  </h1> */}
                  <button
                    className="bg-indigo-900 text-white py-2  px-4 w-full lg:w-75 rounded-full flex items-center shadow-md mt-4 lg:mt-10 text-center justify-center"
                    onClick={() => handleGallery(event)}
                  >
                    {t('event.eventscollection')} <span className="ml-2">&#10132;</span>
                  </button>
                </div>

                {/* Videos Section */}
                <div
                  className="relative bg-black rounded-2xl w-full cursor-pointer mt-8 event-text:h-[570px]"
                  onClick={() => lightbox_open(event.video[0]?.filePath)}
                >
                  <video className="w-full h-[235px] rounded-2xl object-cover event-text:h-[570px]" controls>
                    <source src={event.video[0]?.filePath?.startsWith('blob') ? event.video[0].filePath : `${process.env.REACT_APP_API_URL}/${event.video[0]?.filePath}`} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>

            {/* Video Popup */}
            {lightboxOpen && (
              <div
                id="lightbox"
                className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-50 flex justify-center items-center"
                onClick={lightbox_close}
              >
                <div className="relative w-full max-w-screen-lg">
                  <div className="relative aspect-w-16 aspect-h-9">
                    <video
                      className="w-full h-full object-cover rounded-2xl"
                      controls
                      autoPlay
                      onClick={(e) => e.stopPropagation()} // Prevent closing on video click
                    >
                      <source src={popupVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default EventSection;

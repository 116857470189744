import React, { useEffect, useState } from 'react';

const Channels = () => {
  const [showText, setShowText] = useState(false);

  // Animation timing
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 3000); // 3 seconds delay for the text to show after image animation

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative flex items-center justify-center h-screen bg-gray-900">
      {/* Background Image */}
      <div className="w-full h-full bg-cover bg-center bg-no-repeat absolute top-0 left-0"
        style={{ backgroundImage: "url('')" }}>
      </div>

      {/* Animated Text */}
      <div className={`text-white text-4xl font-bold transition-all duration-1000 ${showText ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'} transform`}>
        {showText ? 'Welcome to Our Site!' : ''}
      </div>

      {/* Initial Text (Before Animation) */}
      <div className={`absolute text-white text-4xl font-bold transition-all duration-1000 ${showText ? 'hidden' : 'block'} transform translate-y-0 opacity-100`}>
        Flip & Rotate Animation!
      </div>
    </div>
  );
};

export default Channels;

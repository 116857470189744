import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from '../../service/apiservice'; 

const Video = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [eventsData, setEventsData] = useState({});
  
  useEffect(() => {
    const fetchEventsData = async () => {
      try {
        const lang = i18n.language;
        const response = await get(`api/festival/festival?lang=${lang}`);
        setEventsData(response.data.data.find(event => event._id === location.state?.eventsData?._id) || {});
      } catch (error) {
        console.error('Failed to fetch events data:', error);
      }
    };

    fetchEventsData();
  }, [i18n.language, location.state?.eventsData?._id]); // Dependencies

  return (
    <div className="py-6 px-4 h-[550px]">
      {eventsData && Object.keys(eventsData).length > 0 ? (
        <div className="text-center text-white mt-[100px]">
          <h1 className="text-2xl sm:text-3xl md:text-3xl text-black font-semibold">
            {i18n.language === 'TA' ? eventsData.title_ta : eventsData.title_en}
          </h1>

          <div className="mt-6 flex justify-center">
            <div className="relative w-[850px] h-[400px]">
              <video className="absolute top-0 left-0 w-full h-full rounded-2xl" controls>
                <source
                  src={eventsData.video[0]?.filePath?.startsWith('blob') ? eventsData.video[0].filePath : `${process.env.REACT_APP_API_URL}/${eventsData.video[0]?.filePath}`}
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <p className="text-lg text-black-600 leading-relaxed text-justify">
            {t('video.noVideoAvailable')}
          </p>
        </div>
      )}
    </div>
  );
};

export default Video;

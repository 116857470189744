import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { get } from '../service/apiservice';
import { Allapi } from '../constant/constantApi';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const Scroll = () => {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const containerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchImages = async (lang) => {
      try {
        const response = await get(`${Allapi.FESTIVAL_getapi}?lang=${lang}`);
        const data = response.data.data;

        const formattedImages = data.map((item) => {
          if (item.image && item.image.filePath) {
            return {
              src: item.image.filePath.startsWith('http')
                ? item.image.filePath
                : `${process.env.REACT_APP_API_URL}/${item.image.filePath}`,
              title_ta: item.title_ta || 'No Tamil Title',
              title_en: item.title_en || 'No English Title',
              description_ta: item.description_ta || 'No Tamil Description',
              description_en: item.description_en || 'No English Description'
            };
          }
          return null;
        }).filter(item => item !== null);

        setImages(formattedImages);
      } catch (error) {
        console.error('Failed to fetch images:', error);
      }
    };

    fetchImages(i18n.language);
  }, [i18n.language]);

  const handleScroll = debounce((e) => {
    e.preventDefault();
    const deltaY = e.deltaY;

    if (deltaY > 0) {
      setCurrentImageIndex(prev => Math.min(prev + 1, images.length - 1));
    } else {
      setCurrentImageIndex(prev => Math.max(prev - 1, 0));
    }
  }, 200);

  const handleGallery = (event) => {
    navigate('/galleryhome', { state: { eventsData: event } });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", handleScroll);

      return () => {
        container.removeEventListener("wheel", handleScroll);
      };
    }
  }, [images]);

  const currentImage = images[currentImageIndex];

  return (
    <div
      ref={containerRef}
      className="relative h-screen w-full overflow-hidden flex rounded-2xl  event-text:w-[1050px]  h-[290px] sm:h-[500px]"
    >
      <div className="relative w-full overflow-hidden">
        <div
          className="relative w-full h-full flex flex-col"
          style={{ 
            transform: `translateY(-${currentImageIndex * 100}%)`, 
            transition: "transform 0.5s ease-in-out" 
          }}
        >
          {images.map((image, index) => (
            <div key={index} className="w-full h-full flex relative">
              <img
                src={image.src}
                alt={`Image ${index + 1}`}
                className="object-fit h-[800px] brightness-50"
                
              />
              <div className="absolute inset-0 flex flex-col justify-end p-6">
                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between gap-4 lg:gap-8 lg:mb-10">
                  <div className="flex flex-col flex-grow text-center lg:text-left pl-10">
                    <h3 className="text-xl sm:text-sm md:text-lg lg:text-3xl text-white event-text:text-4xl">
                      {i18n.language === 'TA' ? image.title_ta : image.title_en}
                    </h3>
                    <p className="text-base sm:text-sm md:text-base lg:text-xl text-white whitespace-normal break-words event-text:text-2xl">
                      {/* {i18n.language === 'TA' ? image.description_ta : image.description_en} */}
                      {i18n.language === `TA`
                          ? `${image.description_ta?.substring(0, 100) ?? ''}...`
                          : `${image.description_en?.substring(0, 100) ?? ''}...`}
                    </p>
                  </div>
                  <div className="flex items-center justify-center lg:justify-start mt-4 lg:mt-10">
                    <button 
                      onClick={() => navigate('/eventhome')} 
                      className="flex items-center bg-indigo-900 text-white px-3 py-2 sm:px-4 rounded-full text-sm sm:text-base whitespace-nowrap"
                    >
                      {t('festival.readMore')}
                      <i className="fa fa-arrow-right text-white pl-3" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Scroll;

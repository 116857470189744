import React, { useState, useEffect } from 'react';
import { get } from '../../service/apiservice';
import { useTranslation } from 'react-i18next';
import { Allapi } from '../../constant/constantApi';
import Header from '../../header/header';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import malar from './images/malar.jpeg';

function Invitation() {
  const { t, i18n } = useTranslation();
  const [invitationItems, setInvitationItems] = useState([]);

  useEffect(() => {
    const getNewsData = async (lang) => {
      try {
        const response = await get(`${Allapi.INVITATION_getapi}?lang=${lang}`);
        setInvitationItems(response.data.data);
      } catch (error) {
        console.error('Failed to fetch news data:', error);
      }
    };

    getNewsData(i18n.language);
  }, [i18n.language]);

  // Helper function to check file type
  const isImage = (filename) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(filename);
  };

  return (
    <>
      <Header />
      {invitationItems?.filter(event => event.isDisabled).map(items => (
        <div id="invitation" className="relative z-1 invitation-page bg-gray-100 flex flex-col justify-between items-center text-center p-5 pt-20 text-black">
          <div className="invitationcontent flex flex-col items-center flex-1 p-5">
            <div className="invitation-text p-5 rounded-lg">
              <h1 className="font-semibold mb-8 text-2xl 2xl:text-4xl sm:text-3xl font-mono relative pb-3 mb-10 uppercase">
                {i18n.language === 'TA' ? items.title_ta : items.title_en}
                <span className="block w-7 h-2 bg-gray-500 absolute bottom-3 left-1/2 -translate-x-1/2" />
                <span className="block w-24 h-px bg-gray-400 mt-6 mx-auto" />
              </h1>
              <p className="text-lg sm:text-base 2xl:text-2xl my-2">
                {i18n.language === 'TA' ? items.description_ta : items.description_en}
              </p>
              <p className="text-lg sm:text-base 2xl:text-2xl my-2">
                {i18n.language === 'TA' ? items.date_ta : items.date_en} <br />
              </p>
              <p className="text-lg sm:text-base 2xl:text-2xl my-2">
                {i18n.language === 'TA' ? items.location_ta : items.location_en}
              </p>
              <p className="text-lg sm:text-base 2xl:text-2xl my-2">
                {i18n.language === 'TA' ? items.sub_description_ta : items.sub_description_en}
              </p>
              <div className="flex justify-center items-center">
                {isImage(items.file) ? (
                  <img
                    src={items.file.startsWith('http') ? items.file : `${process.env.REACT_APP_API_URL}/public/${items.file}`}
                    alt="Invitation"
                    className="mt-12 w-[100%] sm:w-[100%] lg:w-[70%] h-[100%] sm:h-[100%] shadow-lg rounded-lg mb-5"
                  />
                ) : (
                  <div>
                    <p className="text-black font-semibold text-lg bottom-12">{t('invitation.pdfcontent')}

                    <Button
                      onClick={() => {
                        window.open(items.file.startsWith('http') ? items.file : `${process.env.REACT_APP_API_URL}/public/${items.file}`, '_blank', 'noopener,noreferrer'
                        );
                      }}
                      sx={{
                        backgroundColor: '#172554',
                        color: 'white',
                        borderRadius: '1rem',
                        marginTop:'2%',
                        padding: '2%',
                        '&:hover': {
                          backgroundColor: '#172554FF',
                        },

                      }}
                    >
                       {t('invitation.invitation')}
                    </Button>
                    </p>

                    

                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      
    </>
  );
}

export default Invitation;
